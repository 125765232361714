<template>
  <div class="row">
    <div class="col-12 col-md-4 card">
      <div class="card-body d-flex align-items-center">
        <img src="/images/logo.png" width="100%" alt="" />
      </div>
    </div>
    <div class="col-12 col-md-8">
      <div class="card-title p-3">{{ company.name }}</div>
      <div class="card-body">
        <div>
          <strong>Owner: </strong><span>{{ company.ownerName }}</span>
        </div>
        <div>
          <strong>Email: </strong><span><a :href="`mailto:${company.email}`">{{ company.email }}</a></span>
        </div>
        <div>
          <strong>Phone: </strong><span>{{ company.phone }}</span>
        </div>
        <div>
          <strong>Address: </strong><span>{{ company.address }}</span>
        </div>
        <div>
          <strong>State: </strong><span>{{ company.state }}</span>
        </div>
        <div>
          <strong>Postcode: </strong><span>{{ company.postcode }}</span>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-md-4">
          <div
            :class="{ 'bg-primary text-white': active === 'reports' }"
            class="card"
            @click="active = 'reports'"
          >
            <div
              class="
                card-body
                d-flex
                align-items-center
                flex-column
                justify-content-center
              "
            >
              <h5>Reports</h5>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div
            :class="{ 'bg-primary text-white': active === 'products' }"
            class="card"
            @click="active = 'products'"
          >
            <div
              class="
                card-body
                d-flex
                align-items-center
                flex-column
                justify-content-center
              "
            >
              <h5>Products</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" v-if="active === 'reports'">
      <h5>List of Reports ({{ count }})</h5>
      <CardTableReports
        class="mt-4"
        :allItemsCount="count"
        :page="page"
        :pages="pages"
        :listOfReports="companyReports"
        :loading="companyReportsIsLoading"
        @export:pdf="GET_REPORT_DETAIL_THEN_EXPORT_PDF"
      ></CardTableReports>
      <PdfLoadingModal
        :loadingDetailModal="loadingDetailModal"
      ></PdfLoadingModal>
    </div>
    <div class="col-12" v-else-if="active === 'products'">
      <h5>List of Products ({{ allItemsCount }})</h5>
      <CardTableProducts
        :allItemsCount="allItemsCount"
        :headers="headers"
        :listOfProducts="listOfProducts"
        :loading="companyProductsIsLoading"
        :page="page"
        :pages="pages"
        @refresh="GET_COMPANY_PRODUCTS"
        @change="SET_PAGINATION"
      ></CardTableProducts>
    </div>
  </div>
</template>

<script>
import exportPDF from "../Exports/exportPDF";
import CardTableReports from "../ui/table/cardTableReports.vue";
import PdfLoadingModal from "../shared/modal/PdfLoadingModal.vue";
import CardTableProducts from "../ui/table/cardTableProducts.vue";

export default {
  mixins: [exportPDF],
  data: () => ({
    active: "",
    allItemsCount: 0,
    headers: [
      { label: "Product", col: 12, md: 12, lg: 5, xl: 5 },
      { label: "Company", col: 12, md: 12, lg: 3, xl: 3 },
      { label: "Actions", col: 12, md: 12, lg: 4, xl: 4 },
    ],
    listOfProducts: [],
    companyProductsIsLoading: false,
    company: {},
    page: 1,
    companyReports: [],
    count: 0,
    companyReportsIsLoading: true,
  }),
  computed: {
    pages() {
      return Math.ceil(this.count / 10);
    },
  },
  watch: {
    active(data) {
      if (data === "products") this.GET_COMPANY_PRODUCTS();
      else if (data === "reports") this.GET_COMPANY_REPORTS();
    },
  },
  methods: {
    SET_PAGINATION(page, req = false) {
      this.$router.replace({ query: { page } });
      this.page = page;
      if (req) {
        this.GET_COMPANY_REPORTS();
      }
    },
    async GET_COMPANY_DETAIL() {
      const response = await this.$api.get(
        `companies/${this.$route.params.id}`
      );
      if (response.message === "OK" && response.result === "OK") {
        this.company = response.data;
      }
      //else this.$router.push('/error');
    },
    async GET_COMPANY_REPORTS() {
      this.companyReportsIsLoading = true;
      const response = await this.$api.get(
        `CompanyReports/ByCompany?companyId=${this.company.id}&isExcel=0&sortBy=date-desc&offset=${this.page}&limit=10&isCompleted=1`
      );
      if (response.message === "OK" && response.result === "OK") {
        this.count = response.data.count;
        this.companyReports = response.data.items;
      } else this.companyReports = [];
      this.companyReportsIsLoading = false;
    },
    async GET_COMPANY_PRODUCTS(data = "") {
      if (data !== "") this.isSearched = true;
      this.loading = true;
      const response = await this.$api.get(
        `CompanyProducts?offset=${this.page}&limit=10&searchKey=${data}&companyId=${this.company.id}`
      );
      if (response.result === "OK" && response.message === "OK") {
        this.listOfProducts = response.data.items;
        if (response.data.count) this.allItemsCount = response.data.count;
      } else this.listOfProducts = [];
      this.loading = false;
    },
  },
  mounted() {
    this.GET_COMPANY_DETAIL();
    const query = this.$route.query;
    if (query.page) this.page = Number(query.page);
  },
  components: { CardTableReports, PdfLoadingModal, CardTableProducts },
};
</script>

<style>
</style>